import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject, Subscription, map, of } from 'rxjs';

import { environment } from 'src/environments/environment';
import { AuthEndpointMapper } from '../../mappers/auth-endpoints.mapper';
import { Loja, UserLoginReq, UserLoginRes } from '../../models/user/user-login.model';
import { UserLoginMock } from '../../mocks/user-login.mock';
import { UserRegisterReq, UserRegisterRes } from '../../models/user/user-register.model';
import { UserRegisterMock } from '../../mocks/user-register.mock';
import { StorageService } from '../storage/storage.service';
import { ForgotPasswordReq } from '../../models/user/forgot-password.model';
import { IntercomService } from '../intercom/intercom.service';
import { RecoverPasswordReq } from '../../models/user/recover-password.model';
import { ResponseModel } from '../../models/response.model';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {

  private dataSubject: Subject<any> = new Subject<any>();
  private USER_DATA: UserLoginRes;
  private STORAGE_AVAILABLE_SUBS: Subscription;

  store$: Observable<any> = this.dataSubject.asObservable();
  authSubj: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  constructor(
    private http: HttpClient,
    private router: Router,
    private icService: IntercomService,
    private storage: StorageService,
    private baseService: BaseService
  ) {
    this.STORAGE_AVAILABLE_SUBS = this.icService.getStorageAvailableSubj()
      .subscribe(async (isAvailable) => {
        if (isAvailable) this.loadLocalAuthData();
      });
  }

  ngOnDestroy(): void {
    this.STORAGE_AVAILABLE_SUBS.unsubscribe();
  }

  userId(): string {
    return this.USER_DATA?.token?.tokenUsuario?.id;
  }

  userEmail(): string {
    return this.USER_DATA?.token?.tokenUsuario?.email;
  }

  storeName(): string {
    return this.USER_DATA?.lojas?.find(loja => loja.lojaCorrente).nome;
  }

  currentStore(): any {
    return this.USER_DATA?.lojas?.find(loja => loja.lojaCorrente);
  }

  getStores(): Loja[] {
    return this.USER_DATA?.lojas;
  }

  getToken(): string {
    return this.USER_DATA?.token.accessToken;
  }

  storeId(): string {
    return this.USER_DATA?.lojas?.find(loja => loja.lojaCorrente).id;
  }

  hasPermission(permissions: string[]): boolean {
    return this.USER_DATA?.token.tokenUsuario.claims
    .filter(val => val.type === 'funcionalidade' && permissions.includes(val.value))?.length > 0;
  }

  isPlatformAdmin(): boolean {
    return this.USER_DATA?.token.tokenUsuario.claims
    .filter(val => val.type === 'grupo' && val.value === 'Owner')?.length > 0;
  }

  updateStoreSubject(data: any) {
    this.dataSubject.next(data);
  }

  is2FaEnabled(): boolean {
    return this.USER_DATA?.token?.eh2Fa;
  }

  isAuthenticated(): boolean {
    return this.USER_DATA?.token?.accessToken ? true : false;
  }

  isAuthenticatedAsync(): Observable<boolean> {
    return this.authSubj.asObservable();
  }

  login(user: UserLoginReq): Observable<ResponseModel<UserLoginRes>> {
    return environment.MOCK.AUTH ?
      of(UserLoginMock.mock) :
      this.http.post(AuthEndpointMapper.UserLogin, user, this.getPublicAuthHeader())
        .pipe(
          map(this.treatSaveUserAccessData)
        );
  }

  validateLogin2Fa(token: string, email: string) {
    return this.http.post(AuthEndpointMapper.ValidateLogin2Fa, {
      codigo: token,
      idLoja: this.storeId(),
      email: email
    }, this.getPublicAuthHeader())
      .pipe(
        map(this.treatSaveUserAccessData)
      );
  }

  recoverPassword(user: RecoverPasswordReq): Observable<UserLoginRes> {
    return environment.MOCK.RECOVER_PASSWORD ?
      of(UserLoginMock.mock) :
      this.http.post(AuthEndpointMapper.RecoverPassword, user, this.getPublicAuthHeader())
        .pipe(
          map(this.treatSaveUserAccessData)
        );
  }

  changeStore(idLoja: any): Observable<any> {
    return this.http.post(AuthEndpointMapper.ChangeStore, idLoja)
        .pipe(
          map(this.treatSaveUserAccessData)
        );
  }

  register(user: UserRegisterReq): Observable<UserRegisterRes> {
    return environment.MOCK.USER_REGISTER ?
      of(UserRegisterMock.mock) :
      this.http.post(AuthEndpointMapper.UserRegister, user, this.getPublicAuthHeader())
        .pipe(
          map(this.treatSaveUserAccessData)
        );
  }

  forgotPassword(user: ForgotPasswordReq): Observable<any> {
    return this.http.post(AuthEndpointMapper.ForgotPassword, user, this.getPublicAuthHeader());
  }

  forgotPasswordAuth(payload): Observable<any> {
    return this.http.post(AuthEndpointMapper.ForgotPasswordAuth, payload);
  }

  logout() {
    this.USER_DATA = null;
    this.storage.clear();
    this.authSubj.next(!!this.USER_DATA?.token?.accessToken);
    this.router.navigate([`/`]);
  }

  enable2Fa() {
    return this.http.post(
      AuthEndpointMapper.Enable2Fa,
      { email: this.USER_DATA.token.tokenUsuario.email },
      this.getAuthHeader()
    );
  }

  disable2Fa() {
    return this.http.post(
      AuthEndpointMapper.Disable2Fa,
      { email: this.USER_DATA.token.tokenUsuario.email },
      this.getAuthHeader()
    );
  }

  validateEnable2Fa(token: string) {
    return this.http.post(
      AuthEndpointMapper.ValidateEnable2Fa,
      {
        codigo: token,
        idLoja: this.storeId(),
        email: this.userEmail()
      },
      this.getAuthHeader())
      .pipe(
        map(this.treatSaveUserAccessData)
      );
  }

  getAuthHeader = (): { headers: HttpHeaders; } => {
    return {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.USER_DATA.token.accessToken}`
      })
    };
  };

  tryToRedirect(state: RouterStateSnapshot) {
    if (
      this.isAuthenticated() &&
      !(state.url.includes('backoffice'))
    ) { this.router.navigate([`backoffice/dashboard`]) }
    else if (
      !this.isAuthenticated() &&
      state.url.includes('backoffice') &&
      !state.url.includes('esqueci-senha')
    ) { this.router.navigate([`/`]); }
  }

  async loadLocalAuthData(): Promise<any> {
    try {
      const result = await this.storage.get('user');
      this.USER_DATA = result;
      this.authSubj.next(!!this.USER_DATA?.token?.accessToken);
      return result;
    }
    catch (e) { console.log(e); }
  }

  private treatSaveUserAccessData = (res: any): UserLoginRes => {
    this.saveUserAccessData(res.data);
    return res;
  };

  renewToken(payload: any) {
    return environment.MOCK.AUTH ?
      of(UserLoginMock.mock) :
      this.http.post(AuthEndpointMapper.RenewToken, payload)
        .pipe(
          map(this.treatSaveUserAccessData)
        );
  }

  saveNewStore(store: any): Observable<any> {
    return this.http.post(AuthEndpointMapper.NewStore, store)
      .pipe(
        map(this.treatSaveUserAccessData)
      );
  }

  blockUser(idUser: any) {
    return this.http.delete(`${AuthEndpointMapper.BlockUser}?${this.baseService.parseToQueryString(idUser)}`);
  }

  ubnblockUser(idUser: any) {
    return this.http.put(`${AuthEndpointMapper.UnblockUser}?idUsuario=${idUser}`, null);
  }

  private saveUserAccessData(res: any) {
    this.USER_DATA = res;
    this.authSubj.next(!!this.USER_DATA?.token?.accessToken);
    this.storage.set('user', res);
  }

  private getPublicAuthHeader = (): { headers: HttpHeaders; } => {
    return {
      headers: new HttpHeaders({
        'x-scope': 'public'
      })
    };
  };

}
