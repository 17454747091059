export class UserLoginMock {
    static mock: any = {
        token: {
            "accessToken": "00000000000000000000000000.00000000000000000000000000.0000000000000000000000000000.00000000000000000000000000000",
            "expiresIn": 28800,
            "data": {
                "id": "00000000-000-00000-0000-000000000000",
                "nome": "+5583981857741",
                "email": "pacellyjcax@gmail.com",
                "claims": [
                    {
                        "value": "pacellyjcax@gmail.com",
                        "type": "name"
                    },
                    {
                        "value": "00000000-000-00000-0000-000000000000",
                        "type": "sub"
                    },
                    {
                        "value": "pacellyjcax@gmail.com",
                        "type": "email"
                    },
                    {
                        "value": "00000000-000-00000-0000-000000000000",
                        "type": "jti"
                    },
                    {
                        "value": "1698367819",
                        "type": "nbf"
                    },
                    {
                        "value": "1698367819",
                        "type": "iat"
                    },
                    {
                        "value": "Administrador",
                        "type": "role"
                    },
                    {
                        "value": "+5583981857741",
                        "type": "phone"
                    },
                    {
                        "value": "d90bf84c-6a82-44f2-8fd0-d0320c2ef2df",
                        "type": "idLoja"
                    }
                ]
            }
        }
        ,
        "lojas": [
            {
                "id": "d90bf84c-6a82-44f2-8fd0-d0320c2ef2df",
                "nome": "inny",
                "lojaCorrente": true
            }
        ]
    }
}