import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IntercomService {
  private storageAvailable: Subject<boolean> = new Subject<any>();

  getStorageAvailableSubj(): Subject<boolean> {
    return this.storageAvailable;
  };
}