export const environment = {
  PRODUCTION: true,

  APIS: {
    MAIN: 'https://checkout.gateway.sourcefile.com.br/paysec-admin/api',
    IDENTITY: 'https://checkout.gateway.sourcefile.com.br/auth/api',
    PLAN: 'https://checkout.gateway.sourcefile.com.br/assinatura/api'
  },

  MOCK: {
    AUTH: false,
    USER_REGISTER: false,
    RECOVER_PASSWORD: false,
    CHECKOUT_UPSELL: false,
    PRODUCT: false,
    ORDER: false,
    GATEWAY: false,
    PURCHASE_BUTTON: false,
    KITS: false,
    PERSONALIZATIONS: false,
    DASHBOARD: true,
    PLATFORM: false,
    USERS_ADM: true
  }
};
