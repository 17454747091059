import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {

  private loadingSubj: Subject<boolean> = new Subject<boolean>();

  private loadingCount: number = 0;

  constructor() { }

  listenLoading() {
    return this.loadingSubj.asObservable();
  }

  triggerLoading(isLoading: boolean = true) {
    isLoading ?
      this.activateLoading() :
      this.deactivateLoading()
  }

  private activateLoading() {
    this.loadingCount++;
    this.loadingSubj.next(true);
  }

  private deactivateLoading() {
    this.loadingCount--;
    if (this.loadingCount <= 0) {
      this.loadingCount = 0;
      this.loadingSubj.next(false);
    }
  }
}
