import { environment } from "src/environments/environment";

export class AuthEndpointMapper {
    static UserLogin: string = `${environment.APIS.MAIN}/usuarioloja/autenticar`;
    static UserRegister: string = `${environment.APIS.MAIN}/usuarioloja`;
    static UserData: string = `${environment.APIS.MAIN}/usuariodado`;
    static NewStore: string = `${environment.APIS.MAIN}/usuarioloja/registrar-nova-loja`;
    static ForgotPassword: string = `${environment.APIS.IDENTITY}/autenticacao/esqueci-minha-senha`;
    static RecoverPassword: string = `${environment.APIS.MAIN}/usuarioloja/registrar-nova-senha`;
    static Enable2Fa: string = `${environment.APIS.IDENTITY}/autenticacao/habilitar-2fa`;
    static Disable2Fa: string = `${environment.APIS.IDENTITY}/autenticacao/desabilitar-2fa`;
    static ValidateEnable2Fa: string = `${environment.APIS.IDENTITY}/autenticacao/validar-habilitar-2fa`;
    static ValidateLogin2Fa: string = `${environment.APIS.MAIN}/usuarioloja/validar-2fa`;
    static RenewToken: string = `${environment.APIS.IDENTITY}/autenticacao/renovar-token`;
    static ForgotPasswordAuth: string = `${environment.APIS.IDENTITY}/autenticacao/esqueci-minha-senha-autenticado`;
    static BlockUser: string = `${environment.APIS.MAIN}/usuarioloja/bloquear-usuario`;
    static UnblockUser: string = `${environment.APIS.MAIN}/usuarioloja/desbloquear-usuario`;
    static ChangeStore: string = `${environment.APIS.MAIN}/usuarioloja/alterar-token-usuario-loja`;
    static Permission : string = `${environment.APIS.IDENTITY}/permissao`;
    static User: string = `${environment.APIS.IDENTITY}/usuario`;
    static CheckUserExists: string = `${environment.APIS.IDENTITY}/usuario/usuario-existe`;
    static AdminStore: string = `${environment.APIS.MAIN}/administrativo/obter-loja`;
    static AdminProfile: string = `${environment.APIS.MAIN}/administrativo/obter-perfil`;
    static AdminUserStore: string = `${environment.APIS.MAIN}/administrativo/obter-usuario-loja`;
}
