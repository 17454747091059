import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage-angular';
import { IntercomService } from '../intercom/intercom.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private _storage: Storage | null = null;

  constructor(
    private storage: Storage,
    private icService: IntercomService
  ) {
    this.init();
  }

  async init() {
    const storage = await this.storage.create();
    this._storage = storage;
    this.icService.getStorageAvailableSubj().next(true);
  }

  set(key: string, value: any) {
    this._storage?.set(key, value);
  }

  async get(key: string) {
    let data =  await this._storage?.get(key);
    return data;
  }

  async clear() {
    await this._storage?.clear();
    this.icService.getStorageAvailableSubj().next(false);
  }
}