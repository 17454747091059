import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes, mapToCanActivate } from '@angular/router';
import { AuthGuard } from './core/guards/auth/auth.guard';
import { PublicGuard } from './core/guards/public/public.guard';

const routes: Routes = [
  {
    path: '',
    canLoad: mapToCanActivate([PublicGuard]),
    loadChildren: () => import('./containers/loggedout-outlet/loggedout-outlet.module').then( m => m.LoggedoutOutletPageModule)
  },
  {
    path: 'convite',
    canLoad: mapToCanActivate([PublicGuard]),
    loadChildren: () => import('./containers/loggedout-outlet/loggedout-outlet.module').then(m => m.LoggedoutOutletPageModule)
  },
  {
    path: 'backoffice',
    canLoad: mapToCanActivate([AuthGuard]),
    loadChildren: () => import('./containers/main-outlet/main-outlet.module').then( m => m.MainOutletPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }, )
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
