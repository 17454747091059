import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class BaseService {

    protected parseObjectToFormData(obj: any): any {
        let formData = new FormData();
        Object.keys(obj).map(key => formData.append(key,obj[key]));
        return formData;
    }

    protected sanitizeBody(obj: any): any {
        Object.keys(obj).map(key => { if (obj[key] === null) delete obj[key];});
        return obj;
    }

    protected parseObjectToFormDataRecursive(data: any) {
      const formData = new FormData();
      this.objectToFormDataRecursive(formData, data);
      return formData;
    }

    public parseToQueryString(data: any) {
      if (Array.isArray(data)) {
        return data.map(object => (
          Object.entries(object)
            .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value as any)}`)
            .join('&')
        )).join('&');
      } else if (typeof data === 'object') {
        return Object.entries(data)
          .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value as any)}`)
          .join('&');
      } else {
        throw new Error('O argumento deve ser um objeto ou um array de objetos.');
      }
    }

    private objectToFormDataRecursive(formData : FormData, data: any, parentKey?: any) {
      if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File) && !(data instanceof Blob)) {
        Object.keys(data).forEach(key => {
          this.objectToFormDataRecursive(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
      } else {
        const value = data == null ? '' : data;
        formData.append(parentKey, value);
      }
    }

}

